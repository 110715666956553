/*----------------------------------*\
  #TYPOGRAPHY
\*----------------------------------*/


// Font stacks

$font-stack-primary   : 'GraphikWide', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-stack-secondary : 'GraphikWide', 'Helvetica Neue', Helvetica, Arial, sans-serif;


// Font settings

$font-stack-body      : $font-stack-primary;
$font-stack-buttons   : $font-stack-secondary;


// Font sizes

$font-size-root       : 10px;       // The font size set on the root html element.

$font-size-huge       : 6.0rem;     // Huge font size

$font-size-big        : 5.0rem;     // Big font size

$font-size-body       : 2.6rem;     // Regular font size

$font-size-tiny       : 1.2rem;     // tiny font size

$font-size-small      : 1.8rem;     // Small font size

$font-size-h1         : 2.6rem;

$font-size-h2         : 2.6rem;

$font-size-h3         : 1.8rem;

$font-size-h4         : 1.6rem;

$font-size-h5         : 1.3rem;

$font-size-h6         : 1.1rem;

$font-size-body-large : 4.0rem;


// Font styles

// @include font-style;

@mixin text-style-desktop-h-1 {
	@include fluid-type(140px, 62px);
	line-height: .8;
	letter-spacing:-0.01em;
}
@mixin text-style-desktop-h-2-super {
	@include fluid-type(120px, 42px);
	font-weight: $font-weight-super;
	line-height: 1;
	letter-spacing:0em;
	@include screen-tablet-landscape-and-bigger {
		line-height:0.9;
		letter-spacing:-0.01em;
	}
}
@mixin text-style-desktop-h-2-medium {
	@include fluid-type(120px, 42px);
	font-weight: $font-weight-medium;
	line-height:1;
	letter-spacing:0em;
	@include screen-tablet-landscape-and-bigger {
		line-height:0.9;
		letter-spacing:-0.01em;
	}
}
@mixin text-style-desktop-h-3-super {
	@include fluid-type(62px, 28px);
	font-weight: $font-weight-super;
	line-height:1.15;
	letter-spacing:0em;
	@include screen-tablet-landscape-and-bigger {
		line-height:1;
		letter-spacing:-0.01em;
	}
}
@mixin text-style-desktop-h-3-medium {
	@include fluid-type(62px, 28px);
	font-weight: $font-weight-medium;
	line-height:1.15;
	letter-spacing:0em;
	@include screen-tablet-landscape-and-bigger {
		line-height:1;
		letter-spacing:-0.01em;
	}
}
@mixin text-style-desktop-xl-super {
	@include fluid-type(42px, 22px);
	font-weight: $font-weight-super;
	line-height:1.1;
	@include screen-tablet-landscape-and-bigger {
		line-height:1;
	}
}
@mixin text-style-desktop-xl-medium {
	@include fluid-type(42px, 22px);
	font-weight: $font-weight-medium;
	line-height:1.1;
	letter-spacing:0em;
	@include screen-tablet-landscape-and-bigger {
		line-height:1;
		letter-spacing:-0.01em;
	}
}
@mixin text-style-desktop-sl-medium {
	@include fluid-type(35px, 22px);
	font-weight: $font-weight-medium;
	line-height:1.1;
	letter-spacing:0em;
	@include screen-tablet-landscape-and-bigger {
		line-height:1;
		letter-spacing:-0.01em;
	}
}
@mixin text-style-desktop-l-medium {
	@include fluid-type(26px, 17px);
	font-weight: $font-weight-medium;
	line-height:1.15;
	letter-spacing: 0em;
	@include screen-tablet-landscape-and-bigger {
		line-height:1.08;
		letter-spacing:-0.01em;
	}
}
@mixin text-style-desktop-l-super {
	@include fluid-type(26px, 17px);
	font-weight: $font-weight-super;
	line-height:1.15;
	letter-spacing: 0em;
	@include screen-tablet-landscape-and-bigger {
		line-height:1.08;
		letter-spacing:-0.01em;
	}
}
@mixin text-style-desktop-m-super {
	@include fluid-type(19px, 14px);
	font-weight: $font-weight-super;
	line-height:1.15;
	letter-spacing:-0.01em;
	@include screen-tablet-landscape-and-bigger {
		line-height:1.05;
		letter-spacing:0em;
	}
}
@mixin text-style-desktop-m-medium { 
	@include fluid-type(19px, 14px);
	font-weight: $font-weight-medium;
	line-height:1.15;
	letter-spacing: 0em;
	@include screen-tablet-landscape-and-bigger {
		line-height:1.05;
		letter-spacing:-0.01em;
	}
}
@mixin text-style-desktop-s-medium {
	@include fluid-type(12px, 12px);
	line-height:1.17;
 	font-weight: $font-weight-medium;
}

// Font line heights
// Divide the the given line height by the font size to give an unitless line height
// @example {design line height} / {font size} = {unitless lineheight}
//
// NOTE: Never use px as line-height value!!!

$font-line-height-body: 1.33; // {design-line-height} / strip-unit($font-size-body);

$font-line-height-h1:   1.33; // {design-line-height} / strip-unit($font-size-h1);

$font-line-height-h2:   1.33; // {design-line-height} / strip-unit($font-size-h2);

$font-line-height-h3:   1.33; // {design-line-height} / strip-unit($font-size-h3);

$font-line-height-h4:   1.5; // {design-line-height} / strip-unit($font-size-h4);

$font-line-height-h5:   1.5; // {design-line-height} / strip-unit($font-size-h5);

$font-line-height-h6:   1.5; // {design-line-height} / strip-unit($font-size-h6);


// Font weights
$font-weight-ultralight : 100;

$font-weight-light      : 200;

$font-weight-book       : 300;

$font-weight-regular    : 400;

$font-weight-medium     : 500;

$font-weight-bold       : 600;

$font-weight-extrabold  : 700;

$font-weight-black      : 800;

$font-weight-super      : 600;
