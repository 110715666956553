/*----------------------------------*\
  #SETTINGS
\*----------------------------------*/

//
// CONTENTS
//
// SETTINGS
// Media...............Manage our media query settings
// Typography..........Manage our typographical settings
// Colors..............Manage our color settings
// Eases...............Manage our easing settings
// Grid................Manage our grid settings
// Shadows.............Manage our shadow settings
// Spacing.............Manage our spacing settings
//

@import 'settings.colors';