@charset 'UTF-8';

/*------------------------------------*\
  #MAIN
\*------------------------------------*/


/**
 * CONTENTS
 *
 * UTILITIES
 * Custom..................Custom-specific utilities that are being used by settings.
 *
 * SETTINGS
 * All.....................Because our Settings layer doesn’t actually produce any
 *                         CSS, we can safely glob all of the files into one import
 *                         without risk of bloating our compiled stylesheet. This
 *                         also allows us to easily recycle all of our
 *                         project-level settings into other Sass file/projects.
 *                         Please see `00_settings/_all.scss` for a full table of
 *                         contents.
 *
 * TOOLS
 * All.....................Because our Tools layer doesn’t actually produce any
 *                         CSS, we can safely glob all of the files into one import
 *                         without risk of bloating our compiled stylesheet. This
 *                         also allows us to easily recycle all of our
 *                         project-level tooling into other Sass file/projects.
 *                         Please see `01_tools/_all.scss` for a full table of
 *                         contents.
 *
 * GENERIC
 * Normalize...............A level playing field.
 * Html....................Default HTML styling
 *
 *
 * ELEMENTS
 * All.....................Because our Tools layer doesn’t actually produce any
 *                         CSS, we can safely glob all of the files into one import
 *                         without risk of bloating our compiled stylesheet. This
 *                         also allows us to easily recycle all of our
 *                         project-level tooling into other Sass file/projects.
 *                         Please see `20_elements/_all.scss` for a full table of
 *                         contents.
 *
 * OBJECTS
 * Background image........Background position / styling classes
 * Background..............Background color classes
 *
 * COMPONENTS
 * Button..................Specific styling for Buttons based on classes
 *
 * SCOPES
 * Rich text...............Set up a new styling context for long-format text.
 *
 * UTILITIES
 * Flex....................Flexbox classes
 * Text....................Text classes
 *
 */

@import '../00_settings/all';
@import '00_settings/all';

@import '../01_tools/all';

@import '40_components/components.browsersync-notification';
@import '40_components/components.debug-data';
@import '40_components/components.grid-overlay';
@import '40_components/components.info';
@import '40_components/components.pages-list';
@import '40_components/components.svg-collection';
@import '40_components/_styleguide/components.block-fonts';
@import '40_components/_styleguide/components.block';

@import '70_utilities/utilities.debug-page';
