/*----------------------------------*\
  #SPACING
\*----------------------------------*/

//
//    Set the spacing for each viewport
//
//    @see: utiltiies/utilties.spacing
//
//    @example: .u-margin-top--xs
//

// 
// 
// --------------------------------------------------------------------
// 
// 
//

$gutter : 4px * 10;

$gutter-vertical-micro     : $gutter * .25;  /* 10 */
$gutter-vertical-small     : $gutter * .5;   /* 20 */
$gutter-vertical-regular   : $gutter;        /* 40 */
$gutter-vertical-big       : $gutter * 2;    /* 80 */
$gutter-vertical-bigger    : $gutter * 3;    /* 120 */
$gutter-vertical-huge      : $gutter * 4;    /* 160 */
$gutter-vertical-biggest   : $gutter * 5;    /* 200 */
$gutter-vertical-insane    : $gutter * 6;    /* 240 */


$gutter-horizontal-regular : $gutter * .5;   /* 20 */


// $global-vertical-spacing : 160px;

// 
// 
// --------------------------------------------------------------------
// 
// 

$global-spacing     : 10px;
$global-spacing-xs  : $global-spacing;
$global-spacing-sm  : $global-spacing*2;
$global-spacing-md  : $global-spacing*3;
$global-spacing-lg  : $global-spacing*4;
$global-spacing-xlg : $global-spacing*5;

$spacing-sizes: (
                 // Spacing modifier
                 // .u-margin-top--{ modifier }
                 'xlg' : (
                      // Breakpoint triggers
                     'xxlg': $global-spacing*8,
                     'xlg': $global-spacing*8,
                     'lg': $global-spacing*4,
                     'md': $global-spacing*3,
                     'sm': $global-spacing*2,
                     'xs': $global-spacing*2
                 ),
                 'lg'  : (
                     'xxlg': $global-spacing*6,
                     'xlg': $global-spacing*6,
                     'lg': $global-spacing*4,
                     'md': $global-spacing*2,
                     'sm': $global-spacing*2,
                     'xs': $global-spacing*2
                 ),
                 'md'  : (
                     'xxlg': $global-spacing*4,
                     'xlg': $global-spacing*4,
                     'lg': $global-spacing*4,
                     'md': $global-spacing*2,
                     'sm': $global-spacing*2,
                     'xs': $global-spacing*2
                 ),
                 'sm'  : $global-spacing*2,
                 'xs'  : $global-spacing
                );
