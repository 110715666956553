@charset "UTF-8";
/*------------------------------------*\
  #MAIN
\*------------------------------------*/
/**
 * CONTENTS
 *
 * UTILITIES
 * Custom..................Custom-specific utilities that are being used by settings.
 *
 * SETTINGS
 * All.....................Because our Settings layer doesn’t actually produce any
 *                         CSS, we can safely glob all of the files into one import
 *                         without risk of bloating our compiled stylesheet. This
 *                         also allows us to easily recycle all of our
 *                         project-level settings into other Sass file/projects.
 *                         Please see `00_settings/_all.scss` for a full table of
 *                         contents.
 *
 * TOOLS
 * All.....................Because our Tools layer doesn’t actually produce any
 *                         CSS, we can safely glob all of the files into one import
 *                         without risk of bloating our compiled stylesheet. This
 *                         also allows us to easily recycle all of our
 *                         project-level tooling into other Sass file/projects.
 *                         Please see `01_tools/_all.scss` for a full table of
 *                         contents.
 *
 * GENERIC
 * Normalize...............A level playing field.
 * Html....................Default HTML styling
 *
 *
 * ELEMENTS
 * All.....................Because our Tools layer doesn’t actually produce any
 *                         CSS, we can safely glob all of the files into one import
 *                         without risk of bloating our compiled stylesheet. This
 *                         also allows us to easily recycle all of our
 *                         project-level tooling into other Sass file/projects.
 *                         Please see `20_elements/_all.scss` for a full table of
 *                         contents.
 *
 * OBJECTS
 * Background image........Background position / styling classes
 * Background..............Background color classes
 *
 * COMPONENTS
 * Button..................Specific styling for Buttons based on classes
 *
 * SCOPES
 * Rich text...............Set up a new styling context for long-format text.
 *
 * UTILITIES
 * Flex....................Flexbox classes
 * Text....................Text classes
 *
 */
/*----------------------------------*\
  #SETTINGS
\*----------------------------------*/
/*----------------------------------*\
  #MEDIA
\*----------------------------------*/
/*----------------------------------*\
  #TYPOGRAPHY
\*----------------------------------*/
/*----------------------------------*\
  #COLORS
\*----------------------------------*/
/*----------------------------------*\
  #TIMINGS
\*----------------------------------*/
/*----------------------------------*\
  #EASES
\*----------------------------------*/
/*----------------------------------*\
  #GRID
\*----------------------------------*/
/*----------------------------------*\
  #SHADOWS
\*----------------------------------*/
/*----------------------------------*\
  #SPACING
\*----------------------------------*/
/* 10 */
/* 20 */
/* 40 */
/* 80 */
/* 120 */
/* 160 */
/* 200 */
/* 240 */
/* 20 */
/*----------------------------------*\
  #SETTINGS
\*----------------------------------*/
/*----------------------------------*\
  #COLORS
\*----------------------------------*/
.ghost-title {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

/**
 * Media queries targeting a device and smaller
 **/
/**
 * Media queries targeting a device and bigger
 **/
/**
 * Media queries targeting a device and bigger
 * - - screen-mobile-only? > use screen-mobile-and-smaller
 * - - screen-desktop-only? > use screen-desktop-and-bigger
 **/
/**
 * Custom Media queries
 **/
[data-aos="custom-fadeup"] {
  opacity: 0;
  transform: translate3d(0, 40px, 0);
  transition: transform 0.3s cubic-bezier(0, 0, 0, 1) 0.4s, opacity 0s linear 0.4s; }
  [data-aos="custom-fadeup"].aos-animate {
    opacity: 1;
    transform: translate3d(0, 0, 0); }

[data-aos="custom-fadeleft"] {
  opacity: 0;
  transform: translate3d(40px, 0, 0);
  transition: transform 0.3s cubic-bezier(0, 0, 0, 1) 0.4s, opacity 0.1s linear 0.4s; }
  [data-aos="custom-fadeleft"].aos-animate {
    opacity: 1;
    transform: translate3d(0, 0, 0); }

[data-aos="custom-faderight"] {
  opacity: 0;
  transform: translate3d(-40px, 0, 0);
  transition: transform 0.3s cubic-bezier(0, 0, 0, 1) 0.4s, opacity 0.1s linear 0.4s; }
  [data-aos="custom-faderight"].aos-animate {
    opacity: 1;
    transform: translate3d(0, 0, 0); }

[data-aos="custom-fadeup-delay"] {
  opacity: 0;
  transform: translate3d(0, 40px, 0);
  transition: transform 0.3s cubic-bezier(0, 0, 0, 1) 0.6s, opacity 0.1s linear 0.6s; }
  [data-aos="custom-fadeup-delay"].aos-animate {
    opacity: 1;
    transform: translate3d(0, 0, 0); }

body[data-aos-delay='50'] [data-aos], [data-aos][data-aos][data-aos-delay='50'] {
  transition-delay: 0s; }
  body[data-aos-delay='50'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='50'].aos-animate {
    transition-delay: 50ms; }

body[data-aos-delay='100'] [data-aos], [data-aos][data-aos][data-aos-delay='100'] {
  transition-delay: 0s; }
  body[data-aos-delay='100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='100'].aos-animate {
    transition-delay: 100ms; }

body[data-aos-delay='150'] [data-aos], [data-aos][data-aos][data-aos-delay='150'] {
  transition-delay: 0s; }
  body[data-aos-delay='150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='150'].aos-animate {
    transition-delay: 150ms; }

body[data-aos-delay='200'] [data-aos], [data-aos][data-aos][data-aos-delay='200'] {
  transition-delay: 0s; }
  body[data-aos-delay='200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='200'].aos-animate {
    transition-delay: 200ms; }

body[data-aos-delay='250'] [data-aos], [data-aos][data-aos][data-aos-delay='250'] {
  transition-delay: 0s; }
  body[data-aos-delay='250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='250'].aos-animate {
    transition-delay: 250ms; }

body[data-aos-delay='300'] [data-aos], [data-aos][data-aos][data-aos-delay='300'] {
  transition-delay: 0s; }
  body[data-aos-delay='300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='300'].aos-animate {
    transition-delay: 300ms; }

body[data-aos-delay='350'] [data-aos], [data-aos][data-aos][data-aos-delay='350'] {
  transition-delay: 0s; }
  body[data-aos-delay='350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='350'].aos-animate {
    transition-delay: 350ms; }

body[data-aos-delay='400'] [data-aos], [data-aos][data-aos][data-aos-delay='400'] {
  transition-delay: 0s; }
  body[data-aos-delay='400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='400'].aos-animate {
    transition-delay: 400ms; }

body[data-aos-delay='450'] [data-aos], [data-aos][data-aos][data-aos-delay='450'] {
  transition-delay: 0s; }
  body[data-aos-delay='450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='450'].aos-animate {
    transition-delay: 450ms; }

body[data-aos-delay='500'] [data-aos], [data-aos][data-aos][data-aos-delay='500'] {
  transition-delay: 0s; }
  body[data-aos-delay='500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='500'].aos-animate {
    transition-delay: 500ms; }

body[data-aos-delay='550'] [data-aos], [data-aos][data-aos][data-aos-delay='550'] {
  transition-delay: 0s; }
  body[data-aos-delay='550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='550'].aos-animate {
    transition-delay: 550ms; }

body[data-aos-delay='600'] [data-aos], [data-aos][data-aos][data-aos-delay='600'] {
  transition-delay: 0s; }
  body[data-aos-delay='600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='600'].aos-animate {
    transition-delay: 600ms; }

body[data-aos-delay='650'] [data-aos], [data-aos][data-aos][data-aos-delay='650'] {
  transition-delay: 0s; }
  body[data-aos-delay='650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='650'].aos-animate {
    transition-delay: 650ms; }

body[data-aos-delay='700'] [data-aos], [data-aos][data-aos][data-aos-delay='700'] {
  transition-delay: 0s; }
  body[data-aos-delay='700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='700'].aos-animate {
    transition-delay: 700ms; }

body[data-aos-delay='750'] [data-aos], [data-aos][data-aos][data-aos-delay='750'] {
  transition-delay: 0s; }
  body[data-aos-delay='750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='750'].aos-animate {
    transition-delay: 750ms; }

body[data-aos-delay='800'] [data-aos], [data-aos][data-aos][data-aos-delay='800'] {
  transition-delay: 0s; }
  body[data-aos-delay='800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='800'].aos-animate {
    transition-delay: 800ms; }

body[data-aos-delay='850'] [data-aos], [data-aos][data-aos][data-aos-delay='850'] {
  transition-delay: 0s; }
  body[data-aos-delay='850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='850'].aos-animate {
    transition-delay: 850ms; }

body[data-aos-delay='900'] [data-aos], [data-aos][data-aos][data-aos-delay='900'] {
  transition-delay: 0s; }
  body[data-aos-delay='900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='900'].aos-animate {
    transition-delay: 900ms; }

body[data-aos-delay='950'] [data-aos], [data-aos][data-aos][data-aos-delay='950'] {
  transition-delay: 0s; }
  body[data-aos-delay='950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='950'].aos-animate {
    transition-delay: 950ms; }

body[data-aos-delay='1000'] [data-aos], [data-aos][data-aos][data-aos-delay='1000'] {
  transition-delay: 0s; }
  body[data-aos-delay='1000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1000'].aos-animate {
    transition-delay: 1000ms; }

body[data-aos-delay='1050'] [data-aos], [data-aos][data-aos][data-aos-delay='1050'] {
  transition-delay: 0s; }
  body[data-aos-delay='1050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1050'].aos-animate {
    transition-delay: 1050ms; }

body[data-aos-delay='1100'] [data-aos], [data-aos][data-aos][data-aos-delay='1100'] {
  transition-delay: 0s; }
  body[data-aos-delay='1100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1100'].aos-animate {
    transition-delay: 1100ms; }

body[data-aos-delay='1150'] [data-aos], [data-aos][data-aos][data-aos-delay='1150'] {
  transition-delay: 0s; }
  body[data-aos-delay='1150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1150'].aos-animate {
    transition-delay: 1150ms; }

body[data-aos-delay='1200'] [data-aos], [data-aos][data-aos][data-aos-delay='1200'] {
  transition-delay: 0s; }
  body[data-aos-delay='1200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1200'].aos-animate {
    transition-delay: 1200ms; }

body[data-aos-delay='1250'] [data-aos], [data-aos][data-aos][data-aos-delay='1250'] {
  transition-delay: 0s; }
  body[data-aos-delay='1250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1250'].aos-animate {
    transition-delay: 1250ms; }

body[data-aos-delay='1300'] [data-aos], [data-aos][data-aos][data-aos-delay='1300'] {
  transition-delay: 0s; }
  body[data-aos-delay='1300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1300'].aos-animate {
    transition-delay: 1300ms; }

body[data-aos-delay='1350'] [data-aos], [data-aos][data-aos][data-aos-delay='1350'] {
  transition-delay: 0s; }
  body[data-aos-delay='1350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1350'].aos-animate {
    transition-delay: 1350ms; }

body[data-aos-delay='1400'] [data-aos], [data-aos][data-aos][data-aos-delay='1400'] {
  transition-delay: 0s; }
  body[data-aos-delay='1400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1400'].aos-animate {
    transition-delay: 1400ms; }

body[data-aos-delay='1450'] [data-aos], [data-aos][data-aos][data-aos-delay='1450'] {
  transition-delay: 0s; }
  body[data-aos-delay='1450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1450'].aos-animate {
    transition-delay: 1450ms; }

body[data-aos-delay='1500'] [data-aos], [data-aos][data-aos][data-aos-delay='1500'] {
  transition-delay: 0s; }
  body[data-aos-delay='1500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1500'].aos-animate {
    transition-delay: 1500ms; }

body[data-aos-delay='1550'] [data-aos], [data-aos][data-aos][data-aos-delay='1550'] {
  transition-delay: 0s; }
  body[data-aos-delay='1550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1550'].aos-animate {
    transition-delay: 1550ms; }

body[data-aos-delay='1600'] [data-aos], [data-aos][data-aos][data-aos-delay='1600'] {
  transition-delay: 0s; }
  body[data-aos-delay='1600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1600'].aos-animate {
    transition-delay: 1600ms; }

body[data-aos-delay='1650'] [data-aos], [data-aos][data-aos][data-aos-delay='1650'] {
  transition-delay: 0s; }
  body[data-aos-delay='1650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1650'].aos-animate {
    transition-delay: 1650ms; }

body[data-aos-delay='1700'] [data-aos], [data-aos][data-aos][data-aos-delay='1700'] {
  transition-delay: 0s; }
  body[data-aos-delay='1700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1700'].aos-animate {
    transition-delay: 1700ms; }

body[data-aos-delay='1750'] [data-aos], [data-aos][data-aos][data-aos-delay='1750'] {
  transition-delay: 0s; }
  body[data-aos-delay='1750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1750'].aos-animate {
    transition-delay: 1750ms; }

body[data-aos-delay='1800'] [data-aos], [data-aos][data-aos][data-aos-delay='1800'] {
  transition-delay: 0s; }
  body[data-aos-delay='1800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1800'].aos-animate {
    transition-delay: 1800ms; }

body[data-aos-delay='1850'] [data-aos], [data-aos][data-aos][data-aos-delay='1850'] {
  transition-delay: 0s; }
  body[data-aos-delay='1850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1850'].aos-animate {
    transition-delay: 1850ms; }

body[data-aos-delay='1900'] [data-aos], [data-aos][data-aos][data-aos-delay='1900'] {
  transition-delay: 0s; }
  body[data-aos-delay='1900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1900'].aos-animate {
    transition-delay: 1900ms; }

body[data-aos-delay='1950'] [data-aos], [data-aos][data-aos][data-aos-delay='1950'] {
  transition-delay: 0s; }
  body[data-aos-delay='1950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1950'].aos-animate {
    transition-delay: 1950ms; }

body[data-aos-delay='2000'] [data-aos], [data-aos][data-aos][data-aos-delay='2000'] {
  transition-delay: 0s; }
  body[data-aos-delay='2000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2000'].aos-animate {
    transition-delay: 2000ms; }

body[data-aos-delay='2050'] [data-aos], [data-aos][data-aos][data-aos-delay='2050'] {
  transition-delay: 0s; }
  body[data-aos-delay='2050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2050'].aos-animate {
    transition-delay: 2050ms; }

body[data-aos-delay='2100'] [data-aos], [data-aos][data-aos][data-aos-delay='2100'] {
  transition-delay: 0s; }
  body[data-aos-delay='2100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2100'].aos-animate {
    transition-delay: 2100ms; }

body[data-aos-delay='2150'] [data-aos], [data-aos][data-aos][data-aos-delay='2150'] {
  transition-delay: 0s; }
  body[data-aos-delay='2150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2150'].aos-animate {
    transition-delay: 2150ms; }

body[data-aos-delay='2200'] [data-aos], [data-aos][data-aos][data-aos-delay='2200'] {
  transition-delay: 0s; }
  body[data-aos-delay='2200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2200'].aos-animate {
    transition-delay: 2200ms; }

body[data-aos-delay='2250'] [data-aos], [data-aos][data-aos][data-aos-delay='2250'] {
  transition-delay: 0s; }
  body[data-aos-delay='2250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2250'].aos-animate {
    transition-delay: 2250ms; }

body[data-aos-delay='2300'] [data-aos], [data-aos][data-aos][data-aos-delay='2300'] {
  transition-delay: 0s; }
  body[data-aos-delay='2300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2300'].aos-animate {
    transition-delay: 2300ms; }

body[data-aos-delay='2350'] [data-aos], [data-aos][data-aos][data-aos-delay='2350'] {
  transition-delay: 0s; }
  body[data-aos-delay='2350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2350'].aos-animate {
    transition-delay: 2350ms; }

body[data-aos-delay='2400'] [data-aos], [data-aos][data-aos][data-aos-delay='2400'] {
  transition-delay: 0s; }
  body[data-aos-delay='2400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2400'].aos-animate {
    transition-delay: 2400ms; }

body[data-aos-delay='2450'] [data-aos], [data-aos][data-aos][data-aos-delay='2450'] {
  transition-delay: 0s; }
  body[data-aos-delay='2450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2450'].aos-animate {
    transition-delay: 2450ms; }

body[data-aos-delay='2500'] [data-aos], [data-aos][data-aos][data-aos-delay='2500'] {
  transition-delay: 0s; }
  body[data-aos-delay='2500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2500'].aos-animate {
    transition-delay: 2500ms; }

body[data-aos-delay='2550'] [data-aos], [data-aos][data-aos][data-aos-delay='2550'] {
  transition-delay: 0s; }
  body[data-aos-delay='2550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2550'].aos-animate {
    transition-delay: 2550ms; }

body[data-aos-delay='2600'] [data-aos], [data-aos][data-aos][data-aos-delay='2600'] {
  transition-delay: 0s; }
  body[data-aos-delay='2600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2600'].aos-animate {
    transition-delay: 2600ms; }

body[data-aos-delay='2650'] [data-aos], [data-aos][data-aos][data-aos-delay='2650'] {
  transition-delay: 0s; }
  body[data-aos-delay='2650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2650'].aos-animate {
    transition-delay: 2650ms; }

body[data-aos-delay='2700'] [data-aos], [data-aos][data-aos][data-aos-delay='2700'] {
  transition-delay: 0s; }
  body[data-aos-delay='2700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2700'].aos-animate {
    transition-delay: 2700ms; }

body[data-aos-delay='2750'] [data-aos], [data-aos][data-aos][data-aos-delay='2750'] {
  transition-delay: 0s; }
  body[data-aos-delay='2750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2750'].aos-animate {
    transition-delay: 2750ms; }

body[data-aos-delay='2800'] [data-aos], [data-aos][data-aos][data-aos-delay='2800'] {
  transition-delay: 0s; }
  body[data-aos-delay='2800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2800'].aos-animate {
    transition-delay: 2800ms; }

body[data-aos-delay='2850'] [data-aos], [data-aos][data-aos][data-aos-delay='2850'] {
  transition-delay: 0s; }
  body[data-aos-delay='2850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2850'].aos-animate {
    transition-delay: 2850ms; }

body[data-aos-delay='2900'] [data-aos], [data-aos][data-aos][data-aos-delay='2900'] {
  transition-delay: 0s; }
  body[data-aos-delay='2900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2900'].aos-animate {
    transition-delay: 2900ms; }

body[data-aos-delay='2950'] [data-aos], [data-aos][data-aos][data-aos-delay='2950'] {
  transition-delay: 0s; }
  body[data-aos-delay='2950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2950'].aos-animate {
    transition-delay: 2950ms; }

body[data-aos-delay='3000'] [data-aos], [data-aos][data-aos][data-aos-delay='3000'] {
  transition-delay: 0s; }
  body[data-aos-delay='3000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='3000'].aos-animate {
    transition-delay: 3000ms; }

[data-aos] {
  pointer-events: none; }
  [data-aos].aos-animate {
    pointer-events: auto; }

@media screen {
  html:not(.no-js) {
    /**
    * Fade animations:
    * fade
    * fade-up, fade-down, fade-left, fade-right
    * fade-up-right, fade-up-left, fade-down-right, fade-down-left
    */
    /**
    * Zoom animations:
    * zoom-in, zoom-in-up, zoom-in-down, zoom-in-left, zoom-in-right
    * zoom-out, zoom-out-up, zoom-out-down, zoom-out-left, zoom-out-right
    */
    /**
    * Slide animations
    */
    /**
    * Flip animations:
    * flip-left, flip-right, flip-up, flip-down
    */ }
    html:not(.no-js) [data-aos^='fade'][data-aos^='fade'] {
      opacity: 0;
      transition-property: opacity, transform; }
      html:not(.no-js) [data-aos^='fade'][data-aos^='fade'].aos-animate {
        opacity: 1;
        transform: none; }
    html:not(.no-js) [data-aos='fade-up'] {
      transform: translate3d(0, 40px, 0); }
    html:not(.no-js) [data-aos='fade-down'] {
      transform: translate3d(0, -40px, 0); }
    html:not(.no-js) [data-aos='fade-right'] {
      transform: translate3d(-40px, 0, 0); }
    html:not(.no-js) [data-aos='fade-left'] {
      transform: translate3d(40px, 0, 0); }
    html:not(.no-js) [data-aos='fade-up-right'] {
      transform: translate3d(-40px, 40px, 0); }
    html:not(.no-js) [data-aos='fade-up-left'] {
      transform: translate3d(40px, 40px, 0); }
    html:not(.no-js) [data-aos='fade-down-right'] {
      transform: translate3d(-40px, -40px, 0); }
    html:not(.no-js) [data-aos='fade-down-left'] {
      transform: translate3d(40px, -40px, 0); }
    html:not(.no-js) [data-aos^='zoom'][data-aos^='zoom'] {
      opacity: 0;
      transition-property: opacity, transform; }
      html:not(.no-js) [data-aos^='zoom'][data-aos^='zoom'].aos-animate {
        opacity: 1;
        transform: translate3d(0, 0, 0) scale(1); }
    html:not(.no-js) [data-aos='zoom-in'] {
      transform: scale(0.6); }
    html:not(.no-js) [data-aos='zoom-in-up'] {
      transform: translate3d(0, 40px, 0) scale(0.6); }
    html:not(.no-js) [data-aos='zoom-in-down'] {
      transform: translate3d(0, -40px, 0) scale(0.6); }
    html:not(.no-js) [data-aos='zoom-in-right'] {
      transform: translate3d(-40px, 0, 0) scale(0.6); }
    html:not(.no-js) [data-aos='zoom-in-left'] {
      transform: translate3d(40px, 0, 0) scale(0.6); }
    html:not(.no-js) [data-aos='zoom-out'] {
      transform: scale(1.2); }
    html:not(.no-js) [data-aos='zoom-out-up'] {
      transform: translate3d(0, 40px, 0) scale(1.2); }
    html:not(.no-js) [data-aos='zoom-out-down'] {
      transform: translate3d(0, -40px, 0) scale(1.2); }
    html:not(.no-js) [data-aos='zoom-out-right'] {
      transform: translate3d(-40px, 0, 0) scale(1.2); }
    html:not(.no-js) [data-aos='zoom-out-left'] {
      transform: translate3d(40px, 0, 0) scale(1.2); }
    html:not(.no-js) [data-aos^='slide'][data-aos^='slide'] {
      transition-property: transform;
      visibility: hidden; }
      html:not(.no-js) [data-aos^='slide'][data-aos^='slide'].aos-animate {
        visibility: visible;
        transform: translate3d(0, 0, 0); }
    html:not(.no-js) [data-aos='slide-up'] {
      transform: translate3d(0, 100%, 0); }
    html:not(.no-js) [data-aos='slide-down'] {
      transform: translate3d(0, -100%, 0); }
    html:not(.no-js) [data-aos='slide-right'] {
      transform: translate3d(-100%, 0, 0); }
    html:not(.no-js) [data-aos='slide-left'] {
      transform: translate3d(100%, 0, 0); }
    html:not(.no-js) [data-aos^='flip'][data-aos^='flip'] {
      backface-visibility: hidden;
      transition-property: transform; }
    html:not(.no-js) [data-aos='flip-left'] {
      transform: perspective(2500px) rotateY(-100deg); }
      html:not(.no-js) [data-aos='flip-left'].aos-animate {
        transform: perspective(2500px) rotateY(0); }
    html:not(.no-js) [data-aos='flip-right'] {
      transform: perspective(2500px) rotateY(100deg); }
      html:not(.no-js) [data-aos='flip-right'].aos-animate {
        transform: perspective(2500px) rotateY(0); }
    html:not(.no-js) [data-aos='flip-up'] {
      transform: perspective(2500px) rotateX(-100deg); }
      html:not(.no-js) [data-aos='flip-up'].aos-animate {
        transform: perspective(2500px) rotateX(0); }
    html:not(.no-js) [data-aos='flip-down'] {
      transform: perspective(2500px) rotateX(100deg); }
      html:not(.no-js) [data-aos='flip-down'].aos-animate {
        transform: perspective(2500px) rotateX(0); } }

#__bs_notify__ {
  top: auto !important;
  bottom: 0 !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 0px !important; }

/*

    Styling for the debug log that prints out context data.
    Can be injected anywhere so we use !important to make sure no style is overwritten.

    */
.c-debug-data-log {
  width: 100%                    !important;
  min-width: 600px                   !important;
  max-height: 400px                   !important;
  background-color: #2b2a27 !important;
  font-family: consolas, menlo, monaco, monospace !important;
  font-size: 12px                    !important;
  overflow: scroll                  !important;
  padding: 5px 10px                !important;
  z-index: 999                     !important;
  text-align: left                    !important; }
  .c-debug-data-log .function {
    color: #ff5d38 !important; }
  .c-debug-data-log .string {
    color: #26a6a6 !important; }
  .c-debug-data-log .number {
    color: #bcd42a !important; }
  .c-debug-data-log .boolean {
    color: #ff5d38 !important; }
  .c-debug-data-log .null {
    color: #ff5d38 !important; }
  .c-debug-data-log .key {
    color: #ede0ce !important; }

.c-grid-overlay [class*="col-"] {
  background: #ff5454;
  box-shadow: 7.5px -7.5px 0 0 #540000 inset, -7.5px -7.5px 0 0px #000000 inset; }
  @media only screen and (max-width: 29.96875em) {
    .c-grid-overlay [class*="col-"] {
      box-shadow: 7.5px -7.5px 0 0 #540000 inset, -7.5px -7.5px 0 0px #000000 inset; } }

.c-grid-overlay {
  display: none !important;
  height: 100%;
  left: 50%;
  position: fixed;
  pointer-events: none;
  top: 0;
  transform: translate3d(-50%, 0, 0);
  width: 100%;
  z-index: 99; }
  @media screen and (min-width: 480px) and (max-width: 2910px) {
    .c-grid-overlay {
      left: 0;
      transform: none; } }
  .c-grid-overlay.is--default-visible {
    display: block !important; }
  .c-grid-overlay [class*="col-"] {
    height: 100vh;
    opacity: .06; }

.grid-overlay__current-viewport {
  bottom: 0;
  font-size: 1.3rem;
  right: 0;
  position: fixed;
  text-transform: none;
  z-index: 4;
  background-color: #ff5454;
  padding: 10px;
  color: #ffffff;
  font-weight: bold;
  pointer-events: none; }
  .grid-overlay__current-viewport:hover {
    opacity: 0.3; }

.grid-overlay__toggle.c-button {
  bottom: 0;
  font-size: 1.3rem;
  left: 0;
  position: fixed;
  text-transform: none;
  z-index: 4; }
  .grid-overlay__toggle.c-button:hover {
    opacity: 0.3; }

.grid-overlay__check {
  position: absolute;
  height: 0;
  width: 0;
  visibility: hidden; }

.grid-overlay__check:checked + .c-grid-overlay {
  display: block !important; }
  .grid-overlay__check:checked + .c-grid-overlay.is--default-visible {
    display: none !important; }

.c-svg-collection__tile {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  position: relative;
  vertical-align: top;
  border: 1px solid #cacaca;
  padding: 1em 1em 3em 1em;
  margin-bottom: 10px;
  cursor: default; }
  .c-svg-collection__tile h4 {
    color: #333333;
    margin: 0;
    padding-bottom: 3px;
    line-height: 1em;
    font-size: 1em;
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    left: 1em;
    right: 1em;
    bottom: 1em; }

.c-svg-collection__container {
  margin: 0;
  width: 100%;
  height: 144px;
  position: relative;
  background: #cacaca url(data:image/gif;base64,R0lGODlhDAAMAIAAAMzMzP///yH/C1hNUCBEYXRhWE1QPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS4wLWMwNjEgNjQuMTQwOTQ5LCAyMDEwLzEyLzA3LTEwOjU3OjAxICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M1LjEgV2luZG93cyIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDozQjk4OTI0MUY5NTIxMUUyQkJDMEI5NEFEM0Y1QTYwQyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDozQjk4OTI0MkY5NTIxMUUyQkJDMEI5NEFEM0Y1QTYwQyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjNCOTg5MjNGRjk1MjExRTJCQkMwQjk0QUQzRjVBNjBDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjNCOTg5MjQwRjk1MjExRTJCQkMwQjk0QUQzRjVBNjBDIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Af/+/fz7+vn49/b19PPy8fDv7u3s6+rp6Ofm5eTj4uHg397d3Nva2djX1tXU09LR0M/OzczLysnIx8bFxMPCwcC/vr28u7q5uLe2tbSzsrGwr66trKuqqainpqWko6KhoJ+enZybmpmYl5aVlJOSkZCPjo2Mi4qJiIeGhYSDgoGAf359fHt6eXh3dnV0c3JxcG9ubWxramloZ2ZlZGNiYWBfXl1cW1pZWFdWVVRTUlFQT05NTEtKSUhHRkVEQ0JBQD8+PTw7Ojk4NzY1NDMyMTAvLi0sKyopKCcmJSQjIiEgHx4dHBsaGRgXFhUUExIREA8ODQwLCgkIBwYFBAMCAQAAIfkEAAAAAAAsAAAAAAwADAAAAhaEH6mHmmzcgzJAUG/NVGrfOZ8YLlABADs=) top left repeat;
  border: 1px solid #cacaca;
  display: block;
  text-align: center; }
  .c-svg-collection__container i {
    display: inline-block;
    text-indent: 200%;
    white-space: nowrap;
    overflow: hidden; }
  .c-svg-collection__container svg {
    display: block;
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate3d(-50%, -50%, 0); }

.c-styleguide__block-fonts > p {
  display: block !important;
  font-size: 25px !important;
  margin-bottom: 20px !important; }

.c-styleguide__styleblock {
  margin: 60px auto; }

.c-styleguide__header {
  position: relative;
  margin: 0px 0 20px 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #cacaca; }

.c-styleguide__title {
  display: inline-block;
  font-size: 2rem;
  color: #333333; }

.c-styleguide__disclaimer {
  display: inline-block;
  margin-top: 10px;
  margin-left: 20px;
  font-size: 1rem;
  color: #bbaea5; }

html.u-debug_page_ {
  background-color: #ececec;
  color: #333333; }
