/*----------------------------------*\
  #SHADOWS
\*----------------------------------*/

//  Shadow configurations as seen on: http://materializecss.com/

$shadow-depth-0	: 0 0 0 0 rgba(0, 0, 0, 0);
$shadow-depth-1	: 0 2px  4px  0 rgba(0, 0, 0, 0.16);
$shadow-depth-2	: 0 4px  4px 0 rgba(0, 0, 0, 0.16);
$shadow-depth-3	: 0 13px 16px 0 rgba(0, 0, 0, 0.06);
$shadow-depth-4	: 0 16px 28px 0 rgba(0, 0, 0, 0.16);
$shadow-depth-5 : 10px 13px 40px -5px rgba(0, 0, 0, 0.16);
