
.c-styleguide__styleblock {
    margin: 60px auto;
}


.c-styleguide__header {
    position: relative;
    margin: 0px 0 20px 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #cacaca;
}


.c-styleguide__title {
    display: inline-block;
    font-size: 2rem;
    color: #333333;
}


.c-styleguide__disclaimer {
    display: inline-block;
    margin-top: 10px;
    margin-left: 20px;
    font-size: 1rem;
    color: #bbaea5;
}