// @mixin h1 {

//     line-height: $font-line-height-h1;
// 	@include fluid-type($font-size-h1, $font-size-h1*0.833);
// 	// margin-bottom:5px;

// }

// @mixin h2 {

//     line-height: $font-line-height-h2;
// 	@include fluid-type($font-size-h2, $font-size-h2*0.833);
// 	// margin-bottom:5px;

// }

// @mixin h3 {

//     line-height: $font-line-height-h3;
// 	@include fluid-type($font-size-h3, $font-size-h3*0.833);
// 	margin-bottom:0;

// }

// @mixin h4 {

//     font-size: $font-size-h4;
//     line-height: $font-line-height-h4;

// }

// @mixin h5 {

//     color: $color-red;
//     font-size: $font-size-h5;
//     line-height: $font-line-height-h5;

// }

// @mixin h6 {

//     color: $color-red;
//     font-size: $font-size-h6;
//     line-height: $font-line-height-h6;

// }

.ghost-title {
	position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}
